/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function usediaryList() {
  const refdiaryListTable = ref(null);

  const tableColumns = [
    { key: "Data", sortable: false },
    { key: "Usuário", sortable: false },
    { key: "Descrição", sortable: false },
    { key: "Tipo", sortable: false },
  ];

  const perPage = ref(10);
  const totaldiary = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refdiaryListTable.value
      ? refdiaryListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totaldiary.value,
    };
  });

  return {
    tableColumns,
    perPage,
    currentPage,
    totaldiary,
    dataMeta,
    refdiaryListTable,
  };
}
